import styled from 'styled-components';

export const FootContainer = styled.div`
  background: #661988;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;

export const FootStyle = styled.div`
  width: 740px;
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  font-size: 15px;
  line-height: 130%;
  color: #fff;
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
`;
export const ParaText = styled.p`
  margin: 0 !important;
`;
export const LinkStyle = styled.a`
  color: #fff;
  text-decoration: none;
  :hover {
    color: #fff;
    text-decoration: none;
  }
`;
